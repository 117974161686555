// en.js
export default {
  home:{
    home:'Home',
    login:'Login',
  },
  nav:{
    lang:'Language',
    zh:'中文',
    en:'English',
    home:"Home",
    recommend:'Recommendation',
    card:'My Cards',
    property:'My Assets',
    price:'Market',
    friend:'My Friends',
    guild:'Guild',
    more:'More',
    message:'System Notification',
    setup:'Password Setting',
    quit:'Quit'
  },
  card:{
    card:'My Cards',
    price:'Price',
    battle:'Go to Battle '
  },
  carddetail:{
    battle:'Battle',
    battleIng:'In Battle',
    showHour:" Hours",
    showDay:" Days ",
    countdown:"Countdown",
    get:'Daily Earnings',
    day:'Day',
    success:'Success'
  },
  recommend:{
    me:"Me Referral Code",
    title:"Referrer",
    recommend:'Recommendation',
    input:'Please enter the referral code',
    bind:'Binding',
    success:"Success",
    copy_success:"Copied"
  },
  friend:{
    friend:'Friends',
    people1:'Level 1 Users',
    people2:'Level 2 Users',
    people1Name:"Level 1",
    people2Name:"Level 2",
    accout:'Account',
    from:'Type',
    get:'Referral Earnings'
  },
  guild:{
    guild:'Guild',
    people:'Team Count',
    get:'Team Earnings',
    get2:'Team Performance',
  },
  login:{
    login:'Login',
    accout:'Account',
    password:'Password',
    success:"Login Success"
  },
  more:{
    more:'More',
    loading:'Product Under Development.'
  },
  price:{
    price:'Market',
    coin:'Currency',
    price1:'Price($)',
    hours:'24H',
  },
  property:{
    property:'Assets',
    my:'My Assets',
    cash:'Withdraw',
    cash_limit:'Withdrawal Amount',
    records:"Asset Records",
    WFee:'Withdrawal Fee',
    received:'Actual Amount Received',
    address:'Wallet Address',
    paw:'Asset Password',
    type:{
      0:"System Reward",
      7:"Battle Earnings",
      8:"Referral Earnings",
      20:"Withdraw",
      19:"Freeze",
      21:"Cancel Withdraw"
    }
  },
  setup:{
    setpwd:'Password Setting ',
    setup:'Setting',
    pwd:'password',
    pwd1:'Login Password',
    pwd2:'Asset Password',
    ok:'Confirm Password',
    change:'Confirm Modification',
    return:'Back',
    success:"Success"
  },
  message:{
    detail: "Detail",
  },
  page:{
    more: "Pull down to load more",
    noMore:"No more content",
  },
  pop:{
    ok:'confirm',
    nousername:'Username cannot be empty',
    nopassword:'Password cannot be empty',
    loginError:'Incorrect username or password',
    nodays:'Incorrect combat days selection',
    success:'Success',
    apiError:{
      101:"Username cannot be empty",
      102:"Password cannot be empty",
      103:"Incorrect username or password",
      104:"Exceeded maximum login attempts",
      105:"Account frozen",
      106:"Invitation code not provided",
      100:"Login exception",
      110:"Login account exception",
      111:"User does not exist",
      112:"Incorrect password reset type",
      113:"Confirm password cannot be empty",
      114:"Password must be 8-18 characters long",
      115:"Password and confirm password do not match",
      99 :"System error",
      120:"Incorrect invitation code",
      121:"Recommendation relationship exception",
      122:"Already bound a referrer",
      123:"As an agent, cannot bind another referrer",
      130:"Invalid combat hero ID parameter",
      131:"Invalid chosen hero status",
      132:"Incorrect combat days selection",
      140:"Withdrawal wallet address is required",
      141:"Withdrawal amount is required",
      142:"Withdrawal asset password is required",
      143:"Asset password error",
      144:"Withdrawal amount is out of range",
      145:"Insufficient balance",
      146:"Withdrawal failed, please retry"
    }
  }
};