import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStatus:0, //0未登录 1已登录
  },
  mutations: {
    changeLoginStatus(state,value){
      state.loginStatus = value
    }
  },
  getters:{
    // userToken:function(state){
    //   return JSON.parse(localStorage.getItem(`recharge_${state.currentAppkey}`))
    // }
  },
  actions: {
  },
  modules: {
  }
})
