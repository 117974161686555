import { get,post } from './request.js'

//查询首页信息
export const getPageInfo = params  => get("/Source/indexAll", params);
//登录
export const login = params  => post("/Login/auth", params);
//绑定推荐人
export const bindInvite = params  => post("/User/bind_recommender", params);
//修改密码
export const updatePassword = params  => post("/User/password", params);
// 卡牌列表
export const heroAll = params  => get("/Hero/all", params);
// 作战卡牌列表
export const fightHeros = params  => get("/Hero/fight_list", params);
//去作战
export const fight = params  => post("/Hero/fight", params);
// 我的资产
export const walletInfo = params  => get("/Wallet/info", params);
// 提现手续费
export const withdrawFee = params  => post("/Wallet/withdraw_fee", params);
// 提现
export const withdraw = params  => post("/Wallet/withdraw", params);
// 资产记录
export const transationList = params  => get("/Wallet/transaction_list", params);
// 行情
export const coinPrice = params  => get("/Coin/price", params);
//系统通知
export const notices = params  => get("/News/news_list", params);
//通知详情
export const notice = params  => get("/News/news_mas", params);
//公会详情
export const team = params  =>  get("/Organization/team", params);
//好友统计
export const friendInfo = params  => get("/Organization/friends_info", params);
//好友列表
export const friends = params  => get("/Organization/friends", params);
//好友列表
export const bindInfo = params  => get("/User/bindInfo", params);

