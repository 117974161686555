import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import px2rem from './utils/px2rem'
px2rem()
import { Cell,CellGroup,List,Icon } from 'vant';
Vue.use(Cell).use(CellGroup).use(List).use(Icon);
Vue.config.productionTip = false

import i18n from './lang'


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
