<template>
  <div class="nav_menu">
    <div class="top">
      <i class="close" @click="$parent.navShow = false"></i>
      <div
        class="login_box"
        @click="$router.push({ path: '/login' },() => {},() => {});$parent.navShow = false"
        v-if="$store.state.loginStatus == 0"
      >
        {{ $t('home.login') }}
      </div>
      <div class="logout_box" v-else @click="loginOut">{{ getUserName() }} <span>{{ $t('nav.quit') }}</span></div>
    </div>
    <img
      class="logo"
      :src="logo"
      alt=""
    />
    <div class="language_box">
      <div class="language_c" @click="langShow = !langShow">
        <span>{{ $t('nav.lang') }}</span>
        <div class="language_b">
          <span>{{ $t(`nav.${$i18n.locale}`) }}</span>
          <i :class="`arrow ${langShow ? 'arrow-ani' : 'arrow-ani2'}`"></i>
        </div>
      </div>
      <transition>
        <div class="language_l" v-if="langShow">
          <div class="lang" @click="changeLang('zh')">
            <span>{{ $t('nav.zh') }}</span>
          </div>
          <div class="lang" @click="changeLang('en')">
            <span>{{ $t('nav.en') }}</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="nav_list">
      <div
        class="nav_item"
        v-for="(item, index) in navList"
        :key="index"
        @click="goToPage(item)"
      >
        <span> {{ $t(item.nav) }}</span>
        <van-tag class="nav_tag" type="danger" v-if="item.nav =='nav.message' && showNewFlag">New</van-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageInfo } from '../api/index';
import Vue from 'vue';
import { Tag } from 'vant';
Vue.use(Tag);
export default {
  components: {},
  data() {
    return {
      langShow: false,
      navList: [
        {
          nav: 'nav.home',
          route: '/index',
          isToken: false
        },
        {
          nav: 'nav.recommend',
          route: '/recommend',
          isToken: true
        },
        {
          nav: 'nav.card',
          route: '/card',
          isToken: true
        },
        {
          nav: 'nav.property',
          route: '/property',
          isToken: true
        },
        {
          nav: 'nav.price',
          route: '/price',
          isToken: false
        },
        {
          nav: 'nav.friend',
          route: '/friend',
          isToken: true
        },
        {
          nav: 'nav.guild',
          route: '/guild',
          isToken: true
        },
        {
          nav: 'nav.more',
          route: '/more',
          isToken: false
        },
        {
          nav: 'nav.message',
          route: '/notices',
          isToken: false
        },
        {
          nav: 'nav.setup',
          route: '/setup',
          isToken: true
        }
      ],
      logo:"",
      showNewFlag:false
    };
  },
  computed: {},
  watch: {},
  methods: {
    loginOut() {
      this.$store.commit('changeLoginStatus', 0);
      localStorage.removeItem('userToken');
      this.$parent.navShow = false;
      this.$router.push(
            { path: '/index' },
            () => {},
            () => {}
          );
    },
    changeLang(lang) {
      localStorage.setItem('lang',lang);
      this.$i18n.locale = lang; // 设置当前语言
      this.langShow = false;
    },
    goToPage(item) {
      if (!item.isToken) {
        this.$router.push(
          { path: item.route },
          () => {},
          () => {}
        );
      } else {
        if (this.$store.state.loginStatus == 0) {
          this.$router.push(
            { path: '/login' },
            () => {},
            () => {}
          );
        } else {
          this.$router.push(
            { path: item.route },
            () => {},
            () => {}
          );
        }
      }
      this.$parent.navShow = false;
    },
    getUserName(){
      return JSON.parse(localStorage.getItem('userToken')).username
    },
    getPageInfo(){
      getPageInfo().then((res) => {
        this.logo = res.data.data.logo;

        const rids = JSON.parse(localStorage.getItem('readNoticeId'))
        const nids = JSON.parse(localStorage.getItem('newIds'))
        let that = this
        if(rids && nids){
            nids.forEach(function(e){
              if(!rids.includes(e)){
                that.showNewFlag =true;
    
                return;
              }
            }) 
          
        }else{
          this.showNewFlag = res.data.data.hasNewNotice;
        }
      })
    }

  },
  created() {
    this.getPageInfo()
    const localLang = localStorage.getItem('lang')??"en";
    this.$i18n.locale = localLang;
  },
  mounted() {}
};
</script>
<style lang='less' scoped>
.nav_menu {
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    width: 6.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2rem;
    .close {
      width: 0.6rem;
      height: 0.6rem;
      background: url('../assets/home/close.png');
      background-size: 100% 100%;
    }
    .login_box {
      padding: 0.06rem 0.2rem;
      font-size: 0.3rem;
      border: 0.02rem solid #fff;
      border-radius: 0.08rem;
      color: #fff;
    }
    .logout_box {

      font-size: 0.3rem;
      color: #fff;
      span {
        padding: 0.06rem 0.2rem;
        display: inline-block;
        border: 0.02rem solid #fff;
        border-radius: 0.08rem;
      }
    }
  }
  .logo {
    width: 2.7rem;
    //height: 0.66rem;
    margin-top: 0.4rem;
  }
  .language_box {
    width: 6.6rem;
    display: flex;
    flex-direction: column;
    .language_c {
      width: 100%;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .language_b {
        display: flex;
        align-items: center;
        .arrow {
          width: 0.4rem;
          height: 0.4rem;
          background: url('../assets/home/arrow.png');
          background-size: 100% 100%;
        }
        .arrow-ani {
          animation: arrowAni 120ms 1 linear;
          animation-fill-mode: forwards;
        }
        .arrow-ani2 {
          animation: arrowAni2 120ms 1 linear;
          animation-fill-mode: forwards;
        }
        @keyframes arrowAni {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(90deg);
          }
        }
        @keyframes arrowAni2 {
          0% {
            transform: rotate(90deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
    .language_l {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .lang {
        width: 100%;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 0.02rem solid #313131;
      }
    }
    /* 进入动画 */
    .v-enter-active {
      animation: tip-ani-1 300ms;
    }

    /* 离开动画 */
    .v-leave-active {
      animation: tip-ani-2 300ms;
    }

    @keyframes tip-ani-1 {
      from {
        opacity: 0;
        transform: translateY(-50%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    @keyframes tip-ani-2 {
      from {
        opacity: 1;
        transform: translateY(0%);
      }
      to {
        opacity: 0;
        transform: translateY(-50%);
      }
    }
  }
  .nav_list {
    width: 6.6rem;
    height: calc(100% - 4.6rem);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .nav_item {
      width: 100%;
      height: 1rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      border-bottom: 0.02rem solid #313131;
      .nav_tag{
        margin-left: 0.1rem;
      }
    }
  }
}
</style>