import axios from 'axios'
import qs from 'qs'

//获取当前浏览器请求协议，如果是https协议，则使用https协议，否则使用http协议
const protocol = location.protocol
let axiosbaseURL = protocol === 'https:' ? 'https://api.12twelve.pro/Api' : 'http://api.12twelve.pro/Api'

//let axiosbaseURL = "http://api.12twelve.pro/Api";
const instance = axios.create({
  // 创建axios实例，在这里可以设置请求的默认配置  创建一个新的axios实例
  baseURL: axiosbaseURL,
  timeout: 10000 // 设置超时时间10s
})
// 文档统一设置post请求头。(post请求一般才需要设置)
instance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'

/** 添加请求拦截器 **/
instance.interceptors.request.use(
  config => {
    if (localStorage.getItem('userToken')) {
      config.headers.token = JSON.parse(localStorage.getItem('userToken')).token
    }
    if (config.method === 'get') {
      // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
      config.params = {
        ...config.params
        // t: new Date().getTime()
      }
    }
    return config
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 统一封装get请求
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url,
      params,
      ...config
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log('error-', error)
        reject(error)
      })
  })
}

// 统一封装post请求
export const post = (url, data) => {
  data = qs.stringify(data)
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url,
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// 统一封装put请求
export const put = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'put',
      url,
      data,
      headers: {
        accessToken: ''
      },
      ...config
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
