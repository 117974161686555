// zh.js
export default {
  home:{
    home:'首页',
    login:'登录',
  },
  nav:{
    lang:'语言',
    zh:'中文',
    en:'English',
    home:"首页",
    recommend:'推荐',
    card:'我的卡牌',
    property:'我的资产',
    price:'行情',
    friend:'我的好友',
    guild:'公会',
    more:'更多',
    message:'系统通知',
    setup:'密码设置',
    quit:'退出'
  },
  card:{
    card:'卡牌',
    price:'价格',
    battle:'去作战'
  },
  carddetail:{
    battle:'作战',
    battleIng:'作战中',
    showHour:"小时",
    showDay:"天",
    countdown:"倒计时",
    get:'每天收益',
    day:'天',
    success:'作战成功'
  },
  recommend:{
    me:"我的推荐码",
    title:"推荐人",
    recommend:'推荐',
    input:'请输入推荐人',
    bind:'绑定推荐人',
    success:"绑定成功",
    copy_success:"复制成功"
  },
  friend:{
    friend:'好友',
    people1:'一级人数',
    people2:'二级人数',
    people1Name:"一级",
    people2Name:"二级",
    accout:'账号',
    from:'来源',
    get:'推荐收益'
  },
  guild:{
    guild:'公会',
    people:'团队人数',
    get:'团队收益',
    get2:'团队业绩',
  },
  login:{
    login:'登录',
    accout:'账号',
    password:'密码',
    success:"登录成功"
  },
  more:{
    more:'更多',
    loading:'产品开发中。'
  },
  price:{
    price:'行情',
    coin:'币种',
    price1:'价格($)',
    hours:'24H涨幅',
  },
  property:{
    property:'资产',
    my:'我的资产',
    cash:'提现',
    cash_limit:'提现额度',
    records:"资产记录",
    WFee:'手续费',
    received:'实际到账',
    address:'钱包地址',
    paw:'资产密码',
    type:{
      0:"系统奖励",
      7:"作战收益",
      8:"推荐收益",
      20:"提现",
      19:"冻结",
      21:"取消提现"
    }
  },
  setup:{
    setpwd:'密码设置',
    setup:'设置',
    pwd:'密码',
    pwd1:'登录密码',
    pwd2:'资产密码',
    ok:'确认密码',
    change:'确认修改',
    return:'返回',
    success:"修改成功"
  },
  message:{
    detail: "详情",
  },
  page:{
    more: "下拉加载更多",
    noMore:"没有更多内容",
  },
  pop:{
    ok:'确定',
    nousername:'用户名不能为空',
    nopassword:'密码不能为空',
    loginError:'用户或密码错误',
    nodays:'请选择收益天数',
    success:'提现成功',
    apiError:{
      101:"用户名不能为空",
      102:"密码不能为空",
      103:"用户或密码错误",
      104:"登录次数过多",
      105:"账号冻结",
      106:"账号未填写邀请",
      100:"登录异常",
      110:"登录账号异常",
      111:"用户不存在",
      112:"重置密码类型错误",
      113:"确认密码不能为空",
      114:"密码长度为 8-18 位",
      115:"密码与确认密码不一致",
      99 :"系统错误",
      120:"邀请码错误",
      121:"推荐关系异常",
      122:"已经绑定过推荐人",
      123:"身份为代理，不能再绑定推荐人",
      130:"作战英雄ID传参错误",
      131:"选择的英雄状态无效",
      132:"作战天数选择错误",
      140:"提现钱包地址必填",
      141:"提现数量必填",
      142:"提现资产密码必填",
      143:"资产密码错误",
      144:"提现金额不在规定范围内",
      145:"余额不足",
      146:"提现失败，请重试",
    }
  },

};