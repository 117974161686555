import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import('../views/Recommend.vue')
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/Card.vue')
  },
  {
    path: '/carddetail',
    name: 'carddetail',
    component: () => import('../views/CardDetail.vue')
  },
  {
    path: '/property',
    name: 'property',
    component: () => import('../views/Property.vue')
  },
  {
    path: '/propertyRecords',
    name: 'propertyRecords',
    component: () => import('../views/PropertyRecords.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import('../views/Price.vue')
  },
  {
    path: '/friend',
    name: 'friend',
    component: () => import('../views/Friend.vue')
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('../views/More.vue')
  },
  {
    path: '/guild',
    name: 'guild',
    component: () => import('../views/Guild.vue')
  },
  {
    path: '/notices',
    name: 'notices',
    component: () => import('../views/Notices.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/Notice.vue')
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import('../views/Setup.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
