<template>
  <div id="app">
    <router-view />
    <transition>
      <NavMenu v-if="navShow" />
    </transition>
    <Pop v-if="popShow" :popShow="popShow" />
  </div>
</template>
<script>
import NavMenu from './components/NavMenu.vue';
import Pop from './components/Pop.vue';
export default {
  components: {
    NavMenu,
    Pop
  },
  data() {
    return {
      navShow: false,
      popShow: ''
    };
  },
  computed: {},
  watch: {},
  methods: {
    getPop(val) {
      this.popShow = val;
    }
  },
  created() {
    if (localStorage.getItem('userToken')) {
      this.$store.commit('changeLoginStatus', 1);
    }
    if (localStorage.getItem('lang')) {
      this.$i18n.locale = localStorage.getItem('lang')
    }else{
      this.$i18n.locale = "en"
    }

  },
  mounted() {}
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html {
  touch-action: manipulation;
}

body {
  touch-action: pan-y;
}
html,
body {
  height: 100%;
  background-color: #000;
}
*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
}
span {
  font-size: 0.28rem;
  color: #fff;
}
.btn {
  font-size: 0.28rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0283ef;
  border-radius: 0.1rem;
}
.nav {
  width: 100%;
  height: 1rem;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  .nav_icon {
    width: 0.5rem;
    height: 0.5rem;
    background: url('./assets/home/nav.png');
    background-size: 100% 100%;
    position: absolute;
    left: 0.3rem;
  }
  span {
    font-size: 0.3rem;
    color: #fff;
  }
}
#app {
  display: flex;
  height: 100%;
}
/* 进入动画 */
.v-enter-active {
  animation: tip-ani-1 300ms;
}

/* 离开动画 */
.v-leave-active {
  animation: tip-ani-2 300ms;
}

@keyframes tip-ani-1 {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes tip-ani-2 {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
</style>
