<template>
  <div class="pop">
    <div class="content">
      <span>{{ $t(`pop.${popShow}`) }}</span>
      <div class="btn" @click="$parent.popShow = ''">{{ $t('pop.ok') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['popShow'],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang='less' scoped>
.pop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 6rem;
    height: 4rem;
    background-color: #fff;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0.6rem;
    box-sizing: border-box;
    span {
      font-size: 0.42rem;
      color: #000;
      margin-bottom: 0.3rem;
      text-align: center;
    }
    .btn {
      width: 2rem;
      height: 0.6rem;
      background-color: #0283ef;
      border-radius: 0.1rem;
    }
  }
}
</style>